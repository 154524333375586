<template>
  <CRow class="mb-5">
    <CCol lg="3">
      <TAside>
        <CListGroup>
          <CListGroupItem>
            <FilterForGoodsDelivery :queries.sync="query" />
          </CListGroupItem>
          <CListGroupItem
            v-for="(goods_delivery, index) in goods_deliveries"
            :key="`${index}${goods_delivery.id}`"
            :color="
              goods_delivery_id_selected == goods_delivery.id
                ? 'primary'
                : 'secondary'
            "
          >
            <SCardGoodsDelivery
              class="cursor-pointer"
              @click.native="
                lodash.appendRouteQuery($router, {
                  query: {
                    ...$route.query,
                    goods_delivery_id: goods_delivery.id,
                  },
                })
              "
              :goods_delivery="goods_delivery"
            />
          </CListGroupItem>
          <CListGroupItem v-if="!goods_deliveries.length">
            <TMessageNotFound />
          </CListGroupItem>
          <CListGroupItem v-if="list_fetching">
            <TSpinner :loading="list_fetching" />
          </CListGroupItem>
          <SButtonLoadmore store="warehouse.goods_deliveries" />
        </CListGroup>
      </TAside>
    </CCol>
    <CCol lg="9">
      <CRow v-if="goods_delivery_id_selected">
        <!-- Overview xuất kho VN -->
        <CCol col="12">
          <CCard v-if="goods_delivery_selected">
            <CCardHeader class="d-flex align-items-center">
              <TMessage content="SKUs in goods delivery">
                <template #suffix>
                  <strong class="ml-1">{{ goods_delivery_selected.id }}</strong>
                </template>
              </TMessage>
              <SMessageDeliveryStatus
                class="ml-2"
                :id="goods_delivery_selected.status_id"
              />
              <TSpinner :loading="detail_loading" />
              <div class="d-flex ml-auto">
                <SButtonDeliveryStatus
                  v-for="status in goods_delivery_selected.steps_back"
                  :key="status"
                  :id="status"
                  @click="updateDelivery('previous_status', status)"
                  :disabled="detail_loading"
                />
                <CIcon
                  v-if="!lodash.isEmpty(goods_delivery_selected.steps_back)"
                  name="cil-action-undo"
                  class="mx-2"
                  style="transform: rotate(90deg)"
                />
                <SButtonDeliveryStatus
                  v-for="status in goods_delivery_selected.steps_forward"
                  :key="status"
                  :id="status"
                  @click="updateDelivery('next_status', status)"
                  :disabled="detail_loading"
                />
                <span
                  class="mx-2"
                  v-if="
                    !lodash.isEmpty(goods_delivery_selected.steps_back) ||
                    !lodash.isEmpty(goods_delivery_selected.steps_forward)
                  "
                  >|</span
                >
                <TButtonRefresh
                  @click="
                    $store.dispatch('warehouse.goods_deliveries.detail.refresh')
                  "
                  :options="{ disabled: detail_loading }"
                />
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol col="6">
                  <TMessage
                    content="Total number of boxes above expected"
                    bold
                    class="h5"
                  >
                    <template #suffix>: {{ count_duplicate }} </template>
                  </TMessage>
                </CCol>
                <CCol col="6">
                  <TMessage content="Quantity outbound" bold class="h5">
                    <template #suffix>: {{ count_outbound }} </template>
                  </TMessage>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>

        <!-- Input xuất kho -->
        <CCol col="12">
          <CCard>
            <CCardBody class="p-3">
              <SInputSku
                @enter="enterPicker"
                placeholder="Add SKU or Pallet Id"
                :disabled="
                  goods_delivery_selected.status_id != 'waiting_shipment'
                "
              />
            </CCardBody>
            <CCardFooter>
              <div class="d-flex align-items-center justify-content-end">
                <TButtonQuickView
                  :options="{ content: 'View exported boxes' }"
                  @click="showModalPickedBox = true"
                />

                <TButton
                  @click="showModalPickedBoxError = true"
                  color="danger"
                  variant="ghost"
                  size="sm"
                  icon="cil-magnifying-glass"
                  v-if="boxes_picked_error.length"
                >
                  <template #content>
                    <TMessage
                      class="ml-1"
                      content="Watch data was been error"
                    />
                    <CBadge
                      color="danger"
                      class="ml-1 d-flex align-items-center justify-content-center"
                    >
                      <span style="font-size: 12px">{{
                        boxes_picked_error.length
                      }}</span>
                    </CBadge>
                  </template>
                </TButton>

                <PickedBoxModal
                  title="View exported boxes"
                  :show.sync="showModalPickedBox"
                  :pickers="outbound_pickeds"
                  @focus-quantity:change="updateQuantityPicker"
                  @remove="removePicker"
                  referer_quantity_key="quantity_in_current_delivery"
                  :fetching="outbound_pickers_loading"
                />

                <PickedBoxErrorModal
                  :show.sync="showModalPickedBoxError"
                  :pickers="boxes_picked_error"
                />
              </div>
            </CCardFooter>
          </CCard>
        </CCol>

        <!-- [8] Card hàng đợi nhập kho -->
        <CCol col="8">
          <CCard v-if="goods_delivery_selected">
            <CCardHeader>
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex">
                  <TMessage content="SKUs in goods delivery">
                    <template #suffix>
                      <strong class="ml-1">{{
                        goods_delivery_selected.id
                      }}</strong>
                    </template>
                  </TMessage>
                  <TSpinner :loading="detail_loading" />
                </div>

                <div>
                  <TButton
                    v-if="
                      goods_delivery_selected.status_id == 'waiting_shipment'
                    "
                    color="primary"
                    :messageOptions="{ bold: true }"
                    icon="cid-cloud-upload"
                    @click="showModalImportExcel = true"
                    content="Import excel"
                    class="mr-1"
                    variant="outline"
                  />
                  <TButton
                    color="success"
                    :messageOptions="{ bold: true }"
                    icon="cil-data-transfer-down"
                    :options="{
                      disabled:
                        !picking_boxes.length || outbound_pickers_loading,
                    }"
                    @click="submitPickers"
                  >
                    <template #content>
                      <TMessage content="Export" bold class="ml-2">
                        <template #suffix>
                          <span v-if="lodash.sumBy(picking_boxes, 'quantity')">
                            {{
                              `(${_.sumBy(picking_boxes, "quantity")} ${$t(
                                "boxes"
                              )})`
                            }}
                          </span>
                        </template>
                      </TMessage>
                    </template>
                  </TButton>
                </div>
              </div>
              <ImportExcelSKU
                :show.sync="showModalImportExcel"
                :goods_delivery_id="goods_delivery_id_selected"
                type="outbound"
                @updated="submitExcelSuccess"
              />
            </CCardHeader>
            <CCardBody>
              <TMessageNoData content="No SKU" v-if="!pick_boxes.length" />
              <div v-else class="d-flex flex-wrap align-items-stretch">
                <SCardSkuContainerPicker
                  v-for="(picker, idx) in pick_boxes"
                  :key="picker.id + idx"
                  :box="picker.box"
                  class="w-100"
                  refresh
                  :focus-quantity-ediable="true"
                  :noConfirmOnRemove="true"
                  :focusQuantity="picker.quantity"
                  @focus-quantity:change="updateQuantityPicker(picker, $event)"
                  :removable="true"
                  @updated="updatePalletPicker(picker, $event.pallet_id)"
                  @remove="removePicker(picker, $event)"
                  :removing="picker.removing"
                  :background="getPickingBGColor(picker)"
                  showPallet
                >
                  <template #main-quantity>
                    <TMessageNumber
                      :value="picker.box.quantity_in_current_delivery"
                    />
                  </template>
                </SCardSkuContainerPicker>
              </div>
            </CCardBody>
          </CCard>
        </CCol>

        <!-- [4] Card chi tiết các thông tin thùng xuất kho -->
        <CCol col="4">
          <CCard v-if="goods_delivery_selected">
            <CCardBody class="p-3">
              <div
                class="d-flex ml-auto align-items-center"
                v-if="boxes.length && !detail_loading"
              >
                <TSwitch
                  :checked="sortByPallet"
                  @change="sortByPallet = !sortByPallet"
                />
                <PalletBoxModal
                :show.sync="showModalPallet"
                :box="skuInLBSelected"
                @updated="fetchBoxesGd"
              />
                <TMessage content="Sort by pallet" bold class="ml-2 mt-1" />
              </div>
              <TMessageNoData content="No SKU" v-if="!boxes.length" />

              <div v-if="sortByPallet">
                <div
                  v-for="(pallet, index) in boxes_sort_by_pallet"
                  :key="`${pallet.pallet_id}-${index}`"
                >
                  <div
                    v-if="pallet.pallet_id"
                    class="d-flex align-items-center mt-3"
                  >
                    <TLink
                      :content="pallet.pallet_id"
                      :to="
                        lodash.getReferenceLink(
                          'assemble-to-stock.pallet',
                          pallet.pallet_id
                        )
                      "
                      :messageOptions="{ bold: true }"
                      class="h4 m-0"
                    />
                    <SMessagePalletLocation
                      :location="pallet.pallet.location"
                      v-if="pallet.pallet.location"
                      color="success"
                      class="ml-2 h5 mb-1"
                    />
                  </div>
                  <TMessageNotFound
                    v-if="!pallet.pallet_id && pallet.boxes.length"
                    class="h3 mt-3"
                  />
                  <hr v-if="pallet.boxes.length" class="my-0" />
                  <div class="d-flex flex-wrap w-100">
                    <SCardSkuContainerPicker
                      v-for="box in pallet.boxes"
                      :key="box.box_id"
                      :box="box"
                      class="mt-1 mx-2 w-100"
                      refresh
                      :focus-quantity="box.picked_quantity_in_current_delivery"
                      :background="getPickedBGColor(box)"
                      :focus-quantity-title="$t('Quantity in cont')"
                    >
                      <template
                        #prepend-quantity
                        v-if="
                          pickingBoxQuantityById(
                            box.id,
                            pallet.pallet_id
                          ) && pallet.pallet_id
                        "
                      >
                        <div style="font-size: 20px; font-weight: 400">
                          <TMessageNumber
                            :value="
                              pickingBoxQuantityById(
                                box.id,
                                pallet.pallet_id
                              )
                            "
                            :title="$t('Picking quantity')"
                          />
                        </div>
                      </template>
                      <template #main-quantity>
                        <TMessageNumber
                          :value="box.quantity_in_current_delivery"
                          :title="$t('Quantity in pallets')"
                        />
                      </template>
                      <template #append-actions>
                        <CLink
                          class="ml-2 text-info"
                          title="Option"
                          @click="
                            showModalPallet = true;
                            skuInLBSelected = box;
                          "
                        >
                          <CIcon name="cisChartTable" width="16" />
                        </CLink>
                      </template>
                    </SCardSkuContainerPicker>
                  </div>
                </div>
              </div>
              <div v-else class="d-flex flex-wrap align-items-stretch">
                <SCardSkuContainerPicker
                  v-for="box in boxes"
                  :key="box.id"
                  :box="box"
                  class="w-100"
                  refresh
                  :focusQuantity="box.picked_quantity_in_current_delivery"
                  :background="getPickedBGColor(box)"
                  :focus-quantity-title="$t('Quantity outbound')"
                >
                  <template
                    #prepend-quantity
                    v-if="pickingBoxQuantityById(box.id)"
                  >
                    <div style="font-size: 20px; font-weight: 400">
                      <TMessageNumber
                        :value="pickingBoxQuantityById(box.id)"
                        :title="$t('Picking quantity')"
                      />
                    </div>
                  </template>

                  <template #main-quantity>
                    <TMessageNumber
                      :value="box.quantity_in_current_delivery"
                      :title="$t('Delivery quantity')"
                    />
                  </template>
                </SCardSkuContainerPicker>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import audio from "../../mixins/audio";
import readDWSSKU from "../../mixins/readDWSSKU";
import barcode from "../../mixins/barcode";
import PICK_OUTBOUND from "../../store/pick_outbound.local";
import FilterForGoodsDelivery from "../../components/FilterForGoodsDelivery.vue";
import filterable from "../../mixins/filterable";
import PickedBoxModal from "../../components/PickedBoxModal.vue";
import PalletBoxModal from "../../components/PalletBoxModal.vue";
import PickedBoxErrorModal from "../../components/PickedBoxErrorModal.vue";
import ImportExcelSKU from "../../components/ImportExcelSKU.vue";

export default {
  components: {
    PickedBoxModal,
    PickedBoxErrorModal,
    FilterForGoodsDelivery,
    ImportExcelSKU,
    PalletBoxModal
  },
  mixins: [filterable, barcode, audio, readDWSSKU],
  data() {
    return {
      picking_boxes: [],
      in_api_process_boxes: [],
      boxes_picked_error: [],
      skuInLBSelected: {},
      boxes_sort_by_pallet: [],
      showModalPickedBox: false,
      showModalPickedBoxError: false,
      showModalImportExcel: false,
      sortByPallet: false,
      showModalPallet: false
    };
  },
  mounted() {
    if (this.lodash.isEmpty(this.query)) {
      this.$store.dispatch("warehouse.goods_deliveries.fetch.if-first-time");
    } else
      this.$store.dispatch(
        "warehouse.goods_deliveries.apply-query",
        this.query
      );
  },
  watch: {
    goods_delivery_id_selected: {
      immediate: true,
      handler(id) {
        if (id) {
          this.$store
            .dispatch("warehouse.goods_deliveries.detail.fetch", id)
            .then(() => {
              if (this.$store.getters["printer.value"]?.readyState) {
                this.$store.dispatch(
                  "printer.send-message",
                  JSON.stringify({
                    type: "ReadDWS",
                    Command: id,
                    pageSize: "1",
                  })
                );
              }
            });
          this.fetchOutboundPickers();
          this.fetchPickingBoxes();
        }
      },
    },
    boxes() {
      this.sortByPalletHandle();
    },
  },
  computed: {
    goods_delivery_id_selected() {
      return this.$route.query.goods_delivery_id;
    },
    goods_delivery_selected() {
      return this.$store.getters["warehouse.goods_deliveries.detail"];
    },
    goods_deliveries() {
      return this.$store.getters["warehouse.goods_deliveries.list"];
    },
    list_fetching() {
      return this.$store.getters["warehouse.goods_deliveries.fetching"];
    },
    detail_loading() {
      return this.$store.getters["warehouse.goods_deliveries.detail.loading"];
    },
    outbound_pickers() {
      return this.$store.getters["warehouse.outbound_pickers.list"];
    },
    outbound_pickeds() {
      return this.$store.getters["warehouse.outbound_pickers.list"].map((i) => {
        return {
          ...i,
          quantity_in_current_delivery:
            this.getQuantityInGoodsDeliveryPicked(i.box_id),
        };
      });
    },
    outbound_pickers_loading() {
      return this.$store.getters["warehouse.outbound_pickers.loading"];
    },
    goods_deliveries_creating() {
      return this.$store.getters["warehouse.goods_deliveries.creating"];
    },
    pick_boxes() {
      return [
        ...this.picking_boxes,
        ...this.in_api_process_boxes,
        // ...this.outbound_pickers.filter((p) => p.box),
      ];
    },
    boxes() {
      return (this.goods_delivery_selected?.pivot_boxes || [])
        .map((box) => ({
          ...box,
          id: box.box_id,
          quantity_in_current_delivery: this.getQuantityInGoodsDelivery(
            box.box_id,
            box.order_id
          ),
        }))
        .reduce((acc, cur) => {
          // check duplicate box
          const obj = acc.find((obj) => obj.id === cur.id);
          if (obj) {
            obj.quantity_in_current_delivery +=
              cur.quantity_in_current_delivery;
          } else {
            acc.push({
              ...cur,
              picked_quantity_in_current_delivery: this.pickedBoxQuantityById(
                cur.id
              ),
            });
          }
          return acc;
        }, []);
    },
    count_boxes() {
      return this.goods_delivery_selected?.boxes?.length || 0;
    },
    count_duplicate() {
      return (
        this.lodash.sumBy(this?.boxes, "quantity_in_current_delivery") || 0
      );
    },
    count_outbound() {
      return (
        this.lodash.sumBy(
          this.goods_delivery_selected?.outbound_pickers,
          "quantity"
        ) || 0
      );
    },
  },
  methods: {
    handleReadDWSSKU(e) {
      const data = JSON.parse(e.data);
      if (data.type == "DWS_SKU") {
        this.enterPicker(data.box_id);
      }
    },
    pickedBoxQuantityById(box_id) {
      return this.outbound_pickers
        .filter((i) => i.box_id === box_id)
        .reduce((p, c) => p + c.quantity, 0);
    },
    pickingBoxQuantityById(box_id, pallet_id) {
      if (pallet_id)
        return (
          this.pick_boxes.find(
            (x) => x.id === box_id && x.box.pallet_id === pallet_id
          )?.quantity || 0
        );
      return this.pick_boxes
        .filter((x) => x.id === box_id)
        .reduce((p, c) => p + c.quantity, 0);
    },
    isPalletId(pallet) {
      if (this.lodash.isString(pallet))
        return pallet.charAt(0).toUpperCase() == "P";
      return pallet?.id.charAt(0).toUpperCase() == "P";
    },
    fetchPickingBoxes() {
      this.picking_boxes =
        PICK_OUTBOUND.get(this.goods_delivery_id_selected)?.filter(
          (x) => x.box
        ) || [];
    },
    pickBarcode(e) {
      const code = this.press(e);
      if (code) {
        this.enterPicker(code);
      }
    },
    onEnterDataError(k, type) {
      this.playFail();

      if (k) this.boxes_picked_error.push({ data: k, type });
    },
    enterPicker(picker) {
      if (!this.goods_delivery_id_selected) {
        this.onEnterDataError();

        return;
      }

      if (this.goods_delivery_selected.status_id != "waiting_shipment") {
        this.onEnterDataError();

        return;
      }

      if (this.lodash.isEmpty(picker)) {
        this.onEnterDataError();

        return;
      }

      if (this.isPalletId(picker)) {
        this.$tomoni.warehouse.pallets
          .all({
            include: "pivotBoxes",
            "filter[id]": picker,
          })
          .then((res) => res.data?.data?.[0])
          .then((data) => {
            if (!data?.id) {
              this.$store.commit("toasts.push", {
                title: this.$t("Invalid pallet"),
                message: "There is no valid SKU in the pallet",
                type: "danger",
              });

              this.onEnterSku({ p: picker, t: "fetch-data-fail" });
              return;
            }

            if (data?.pivot_boxes?.length) {
              data.pivot_boxes.forEach((x) => {
                this.onEnterSku(
                  x.box_id,
                  Math.min(
                    x.current_quantity,
                    this.getQuantityInGoodsDelivery(x.box_id, x.order_id)
                  )
                );
              });
            } else {
              this.$store.commit("toasts.push", {
                title: this.$t("Invalid pallet"),
                message: "There is no valid SKU in the pallet",
                type: "danger",
              });
              this.onEnterDataError(picker, "invalid-pallet");
            }
          })
          .catch((err) => {
            console.error(err);
            this.$store.commit("toasts.push", {
              title: this.$t("Invalid pallet"),
              message: "There is no valid SKU in the pallet",
              type: "danger",
            });
            this.onEnterDataError(picker, "invalid-pallet");
          });
      } else {
        this.onEnterSku(picker);
      }
    },
    onValidateGoodDeliveryQuantity(box, next_quantity) {
      let is_quantity_valid =
        box.quantity_in_current_delivery >=
        next_quantity + box.picked_quantity_in_current_delivery;

      if (!is_quantity_valid) {
        this.onEnterDataError(box.id, "over-quantity-in-goods-delivery");

        this.$store.commit("toasts.push", {
          title: this.$t("Invalid sku"),
          message: "Over goods delivery quantity",
          type: "danger",
        });
      }

      return is_quantity_valid;
    },
    onJoinPicking(picker) {
      PICK_OUTBOUND.set(picker, this.goods_delivery_id_selected);
      this.fetchPickingBoxes();
      this.playSuccess();
    },
    onEnterSku(sku, default_quantity = 1) {
      /**
       * 1. Validate exist in good delivery
       * 2. Validate số lượng với số lượng của goods delivery
       */

      let box = this.boxes.find((b) => b.id === sku);

      if (!box) {
        this.$store.commit("toasts.push", {
          title: this.$t("Invalid sku"),
          message: "There is no valid SKU in the goods delivery",
          type: "danger",
        });

        this.onEnterDataError(sku, "not-exist-in-goods-delivery");

        return;
      }

      const current_picking = this.picking_boxes.find((p) => p.box.id === sku);

      let quantity = (current_picking?.quantity || 0) + default_quantity;

      if (!this.onValidateGoodDeliveryQuantity(box, quantity)) return;

      if (current_picking) {
        this.onJoinPicking({
          ...current_picking,
          quantity,
        });
      } else if (box) {
        this.onJoinPicking({
          id: box.id,
          quantity,
          box: { ...box, pallet_id: "" },
          picking: true,
        });
      }
    },
    fetchOutboundPickers() {
      return this.$store.dispatch("warehouse.outbound_pickers.apply-query", {
        "filter[goods_delivery_id]": this.goods_delivery_id_selected,
      });
    },
    fetchBoxesGd() {
      setTimeout(() => {
        this.$store.dispatch("warehouse.goods_deliveries.detail.refresh");
      }, 2000);
    },
    getQuantityInGoodsDelivery(box_id, order_id) {
      return (
        this.goods_delivery_selected.pivot_boxes?.find(
          (b) => b.box_id === box_id && b.order_id === order_id
        )?.quantity || 0
      );
    },
    getQuantityInGoodsDeliveryPicked(box_id) {
      return (
        this.boxes.find(
          (b) => b.box_id === box_id
        )?.quantity_in_current_delivery || 0
      );
    },
    removePicker(picker) {
      if (picker.picking) {
        PICK_OUTBOUND.removePicker(this.goods_delivery_id_selected, picker);
        this.fetchPickingBoxes();
        return;
      }

      this.$set(picker, "removing", true);
      this.$store
        .dispatch("warehouse.outbound_pickers.delete", picker.id)
        .finally(() => {
          this.$set(picker, "removing", false);
        });
    },
    updatePalletPicker(picker, pallet_id) {
      if (picker.picking) {
        PICK_OUTBOUND.set(
          { ...picker, pallet_id: pallet_id },
          this.goods_delivery_id_selected
        );
        this.fetchPickingBoxes();
        return;
      }
    },
    updateQuantityPicker(picker, quantity) {
      if (picker.picking) {
        if (!this.onValidateGoodDeliveryQuantity(picker.box, quantity)) return;

        PICK_OUTBOUND.set(
          { ...picker, quantity },
          this.goods_delivery_id_selected
        );
        this.fetchPickingBoxes();
        return;
      }
      this.$store.dispatch("warehouse.outbound_pickers.update", {
        id: picker.id,
        attributes: { quantity },
      });
    },
    getPickingBGColor(picker) {
      if (!picker.quantity) return "#ffa39e";

      const box = this.boxes?.find((b) => b.id === picker.id);

      if (!box) return "#ffa39e";

      if (
        picker.quantity + box?.picked_quantity_in_current_delivery ===
        box.quantity_in_current_delivery
      )
        return "#d9f7be";

      return "#fff";
    },
    getPickedBGColor(box) {
      if (
        box?.picked_quantity_in_current_delivery >
        box.quantity_in_current_delivery
      )
        return "#ffa39e";

      if (!box.quantity_in_current_delivery) return "#ffa39e";

      if (
        this.pickingBoxQuantityById(box.id) +
          box?.picked_quantity_in_current_delivery ===
        box.quantity_in_current_delivery
      )
        return "#d9f7be";

      return "#fff";
    },
    sortByPalletHandle() {
      // Danh sách các thùng có pallet
      let pivot_pallet_list = this.boxes.reduce(
        (p, c) => {
          let sorted_pivot_pallets = c?.pallets || [];

          return p.concat(
            sorted_pivot_pallets.map((i) => ({
              ...i,
              quantity_in_current_delivery: this.getQuantityInGoodsDelivery(i.laravel_through_key),
              picked_quantity_in_current_delivery: this.pickedBoxQuantityById(i.laravel_through_key),
              pallet_id: i.id,
              pallet: i,
              id: i.laravel_through_key,
            }))
          );
        },
        []
      );
      // Danh sách các thùng chưa lên hết pallet
      let not_enought_pallet_list = this.boxes
        .filter(
          (box) =>
            box.total_quantity_in_lading_bills > box.quantity_in_pallets &&
            box.quantity_in_pallets > 0
        )
        .map((i) => ({
          id: i.id,
          box_id: i.id,
          pallet_id: null,
          pallet: null,
          quantity_in_current_delivery: this.getQuantityInGoodsDelivery(i.id),
          picked_quantity_in_current_delivery: this.pickedBoxQuantityById(i.id),
        }));

      // Danh sách các thùng không tồn tại pallet
      let missing_pallet_list = this.boxes
        .filter((box) => !box.pallets.length)
        .map((i) => ({
          id: i.id,
          box_id: i.id,
          pallet_id: null,
          pallet: null,
          quantity_in_current_delivery: this.getQuantityInGoodsDelivery(i.id),
          picked_quantity_in_current_delivery: this.pickedBoxQuantityById(i.id),
        }));

      // Group pallet và tạo list
      pivot_pallet_list = this.lodash.groupBy(pivot_pallet_list, "pallet_id");
      pivot_pallet_list = Object.entries(pivot_pallet_list).reduce(
        (r, [pallet_id, pivot_boxes]) => {
          if (!pivot_boxes.length) return r;

          r.push({
            pallet_id,
            pallet: pivot_boxes[0].pallet,
            boxes: pivot_boxes,
          });

          return r;
        },
        []
      );

      this.boxes_sort_by_pallet = [
        ...pivot_pallet_list,
        {
          id: null,
          boxes: [...missing_pallet_list, ...not_enought_pallet_list],
        },
      ];
    },
    submitPickers() {
      let data = {
        goods_delivery_id: this.goods_delivery_id_selected,
      };
      this.in_api_process_boxes = [
        ...this.picking_boxes,
        ...this.in_api_process_boxes,
      ];
      this.in_api_process_boxes.forEach((object, index) => {
        const item = {
          id: object.id,
          quantity: object.quantity,
          pallet_id: object.pallet_id,
        };
        const boxItem = this.lodash.mapKeys(
          this.lodash.pickBy(item),
          (value, key) => {
            return `boxes[${index}][${key}]`;
          }
        );
        data = { ...data, ...boxItem };
      });

      this.picking_boxes = [];
      this.in_api_process_boxes.forEach((picker) => {
        PICK_OUTBOUND.removePicker(this.goods_delivery_id_selected, picker);
      });

      this.$store
        .dispatch(
          "warehouse.outbound_pickers.create",
          this.lodash.pickBy(data, this.lodash.identity)
        )
        .then(() => {
          // this.picking_boxes.forEach((picker) => {
          //   PICK_OUTBOUND.removePicker(this.goods_delivery_id_selected, picker);
          // });
          // this.fetchPickingBoxes();
          this.fetchBoxesGd();
          this.fetchOutboundPickers().finally(
            () => (this.in_api_process_boxes = [])
          );
          this.sortByPalletHandle()
        })
        .catch(() => {
          this.in_api_process_boxes.forEach((picker) => {
            PICK_OUTBOUND.set(picker, this.goods_delivery_id_selected);
          });
          this.fetchPickingBoxes();
          this.in_api_process_boxes = [];
        });
    },
    updateDelivery(field, value) {
      this.$store.dispatch("warehouse.goods_deliveries.detail.update", {
        [field]: value,
      });
    },
    submitExcelSuccess() {
      this.fetchBoxesGd();
      this.fetchOutboundPickers();
    },
  },
};
</script>
